<template>
    <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full pl-4 pr-4" >
            <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full" v-for="(data, indexData) in dataColumn" :key="indexData">
                    <div class="w-full mt-4" >
                        <div class="w-full">
                            <label style="font-size:16px" class="font-bold" for="">{{ data.title }}</label>
                        </div>
                        <div class="w-full">
                            <label class="text-grey" for="">{{ data.value }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-4" v-if="dataResponse.length > 0">
            <div class="w-full hover:text-primary cursor-pointer" @click="downloadFile">
                <label for="" style="font-size:16px" class="font-bold">Tải xuống file đối soát</label>
                <div class="w-full">
                    <img class="cursor-pointer mt-2" style="height:100px" src="@/assets/images/logo/export_file.png" alt="Export_File" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dataColumn: [],
        publicURL: {
            type: String
        },
        dataResponse: []
    },
    methods: {
        downloadFile(){
            window.open(this.publicURL, '_blank');
        }
    }
}
</script>

<style>

</style>