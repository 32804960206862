<template>
    <vs-table stripe :max-items="10" pagination :data="dataResponse" class="mt-4" :noDataText="noDataText">
        <template slot="thead">
            <vs-th style="font-size:13px" class="text-center bg-primary text-white"  v-for="(item, itemIndex) in headerTable" :key="itemIndex">{{ item.title }}</vs-th>
        </template>
        <template slot-scope="{data}">
            <tbody>
                <vs-tr :key="indextr" v-for="(compareData, indextr) in data">
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.packageId }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].packageId == compareData.packageId, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].packageId != compareData.id}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].packageId }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.packageName }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].packageName.toUpperCase() == compareData.packageName.toUpperCase(), 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].packageName.toUpperCase() != compareData.packageName.toUpperCase()}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].packageName }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="line-height:62px; max-height:62px">
                                {{ compareData.cameraName }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].cameraName == compareData.cameraName, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].cameraName != compareData.cameraName}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].cameraName }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.paymentDate }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].paymentDate == compareData.paymentDate, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].paymentDate != compareData.paymentDate}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].paymentDate }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px;width:120px !important">
                                {{ compareData.amount }} VNĐ
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].amount == compareData.amount, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].amount != compareData.amount}" style="max-height:62px;line-height:62px;width:120px !important">
                                {{ dataCompare[compareData.index].amount }} VNĐ
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.description }} {{ dataCompare[compareData.index].description }}</vs-td>
                    <vs-td class="md:w-2/12 text-center" style="min-width:115px !important">
                        <div class="w-full flex">
                            <div :class="{'w-full bg-danger text-center text-white': dataCompare[compareData.index].status == 0, 'w-full bg-success text-center text-white': dataCompare[compareData.index].status == 1}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].status == 0 ? 'Không khớp' : 'Trùng khớp'}}
                            </div>
                        </div>
                        
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        headerTable: [],
        dataResponse: [],
        dataCompare: []
    },
    data(){
        return {
            noDataText: 'Dữ liệu trong file không tồn tại trong khoảng thời gian đối soát'
        }
    }
}
</script>

<style>

</style>