<template>
    <vs-table stripe :max-items="10" pagination :data="dataResponse" class="mt-4" :noDataText="noDataText">
        <template slot="thead">
            <vs-th style="font-size:13px" class="text-center bg-primary text-white"  v-for="(item, itemIndex) in headerTable" :key="itemIndex">{{ item.title }}</vs-th>
        </template>
        <template slot-scope="{data}">
            <tbody>
                <vs-tr :key="indextr" v-for="(compareData, indextr) in data">
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                <span class="ml-1 mr-2">{{ compareData.orderId }}</span>
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].orderId.trim() == compareData.orderId.trim(), 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].orderId.trim() != compareData.orderId.trim()}" style="max-height:62px;line-height:62px">
                                <span class="ml-1 mr-2">{{ dataCompare[compareData.index].orderId}}</span>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                <span class="ml-1 mr-2">{{ compareData.transId }}</span>
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].transId == compareData.transId, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].transId != compareData.transId}" style="max-height:62px;line-height:62px">
                                <span class="ml-1 mr-2">{{ dataCompare[compareData.index].transId }}</span>
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.paymentType }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].payType == compareData.paymentType, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].payType != compareData.paymentType}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].payType }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                            <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.amount }} VNĐ
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].amount == compareData.amount, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].amount != compareData.amount}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].amount }} VNĐ
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                            <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.paymentDate }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].paymentDate == compareData.paymentDate, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].paymentDate != compareData.paymentDate}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].paymentDate }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.description }} {{ dataCompare[compareData.index].description }}</vs-td>
                    <vs-td :class="{'hoverFlat bg-danger text-white md:w-2/12 text-center' : dataCompare[compareData.index].status == 0, 'md:w-2/12 text-center' : dataCompare[compareData.index].status == 1}">{{ dataCompare[compareData.index].status == 0 ? 'Không khớp' : 'Trùng khớp'}}</vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            noDataText: 'Dữ liệu trong file không tồn tại trong khoảng thời gian đối soát'
        }
    },
    props: {
        headerTable: [],
        dataResponse: [],
        dataCompare: []
    },
    created(){
        this.dataResponse = this.dataResponse.map(el => {
            el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
            return el;
        })
        this.dataCompare = this.dataCompare.map(el => {
            el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
            return el;
        })
    }
}
</script>

<style>

</style>