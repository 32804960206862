<template>
    <vs-table stripe :max-items="10" pagination :data="dataResponse" class="mt-4" :noDataText="noDataText">
        <template slot="thead">
            <vs-th style="font-size:13px" class="text-center bg-primary text-white"  v-for="(item, itemIndex) in headerTable" :key="itemIndex">{{ item.title }}</vs-th>
        </template>
        <template slot-scope="{data}">
            <tbody>
                <vs-tr :key="indextr" v-for="(compareData, indextr) in data">
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.id }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].userId == compareData.id, 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].userId != compareData.id}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].userId }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.username.trim() }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].username.toUpperCase().trim() == compareData.username.toUpperCase(), 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].username.toUpperCase() != compareData.username.toUpperCase().trim()}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].username.trim() }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12 padding-tr"> 
                        <div class="w-full flex">
                            <div class="w-full bg-success md:w-1/2 text-center text-white" style="max-height:62px;line-height:62px">
                                {{ compareData.paymentDate }}
                            </div>
                            <div :class="{'w-full bg-warning md:w-1/2 text-center text-white' : dataCompare[compareData.index].paymentDate.toUpperCase() == compareData.paymentDate.toUpperCase(), 'w-full bg-danger md:w-1/2 text-center text-white' : dataCompare[compareData.index].paymentDate.toUpperCase() != compareData.paymentDate.toUpperCase()}" style="max-height:62px;line-height:62px">
                                {{ dataCompare[compareData.index].paymentDate }}
                            </div>
                        </div>
                    </vs-td>
                    <vs-td class="md:w-2/12"> {{ compareData.description }} {{ dataCompare[compareData.index].description }}</vs-td>
                    <vs-td :class="{'hoverFlat bg-danger text-white md:w-2/12 text-center' : dataCompare[compareData.index].status == 0, 'md:w-2/12 text-center' : dataCompare[compareData.index].status == 1}">{{ dataCompare[compareData.index].status == 0 ? 'Không khớp' : 'Trùng khớp'}}</vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        headerTable: [],
        dataResponse: [],
        dataCompare: []
    },
    data(){
        return {
            noDataText: 'Dữ liệu trong file không tồn tại trong khoảng thời gian đối soát'
        }
    },
    created(){
        this.dataResponse = this.dataResponse.map(el => {
            el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
            return el;
        })
        this.dataCompare = this.dataCompare.map(el => {
            el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
            return el;
        })
    }
}
</script>

<style>

</style>