<template>
<div id="page-branch-list">
    <div class="vx-row">
        <div class="vx-col w-full mt-2">
            <label for="" style="font-size:18px" class="font-bold">Thông tin chung</label>
            <div class="vx-card w-full p-6">
                <card-user :dataResponse="dataResponse" :dataColumn="dataCompareInfoColumnSetPackage" :publicURL="publicURL" />
            </div>
        </div>
    </div>
    <div class="vx-row mt-4">
        <div class="vx-col w-full mt-2">
            <label style="font-size:18px" class="font-bold" for="">{{ title }}</label>
            <div class="vx-card w-full p-6">
                <div class="vx-row">
                    <div class="vx-col w-full md:w-1/12 flex" style="min-width:150px">
                        <div class="bg-primary text-white round" style="width:20px; height:20px; backgrou">
                        </div>
                        <span class="ml-2">Dữ liệu lưu trữ</span>
                    </div>
                    <div class="vx-col w-full md:w-1/12 flex" style="min-width:150px">
                        <div class="bg-warning text-white round" style="width:20px; height:20px; backgrou">
                        </div>
                        <span class="ml-2">Dữ liệu từ file</span>
                    </div>
                    <div class="vx-col w-full md:w-2/12 flex" style="min-width:150px">
                        <div class="bg-danger text-white round" style="width:20px; height:20px; backgrou">
                        </div>
                        <span class="ml-2">Dữ liệu không khớp</span>
                    </div>
                    <div class="vx-col w-full md:w-2/12 flex" style="min-width:150px">
                        <span class="ml-2">Dữ liệu hợp lệ: </span> <span class="text-success font-bold ml-1">{{ countDataDetail.success }}</span>
                    </div>
                    <div class="vx-col w-full md:w-2/12 flex" style="min-width:150px">
                        <span class="ml-2">Dữ liệu lỗi: </span> <span class="text-danger font-bold ml-1">{{ countDataDetail.error }}</span>
                    </div>
                    <vs-button icon-pack="feather" icon="icon-arrow-left" type="border" class="ml-auto mr-4" @click="$router.push('/user/reconcile-list-history')">Quay lại</vs-button>
                </div>
                <div class="vx-row mt-1 compare">
                    <div class="vx-col w-full">
                        <table-user :dataCompare="dataCompare" :headerTable="headerTable" :dataResponse="dataResponse" v-if="compareInfo.templateType == 1" />
                        <table-package :dataCompare="dataCompare" :headerTable="headerTable" :dataResponse="dataResponse" v-if="compareInfo.templateType == 2" />
                        <table-payment :dataCompare="dataCompare" :headerTable="headerTable" :dataResponse="dataResponse" v-if="compareInfo.templateType == 5" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TableUser from './table/TableUser.vue';
import CardUser from './card/CardUser.vue';
import TablePackage from './table/TablePackage.vue'
import TablePayment from './table/TablePayment.vue'
import moment from 'moment';
export default {
    components: {
        TableUser,
        CardUser,
        TablePackage,
        TablePayment
    },
    data(){
        return {
            countDataDetail: {},
            publicURL: null,
            dataCompareInfoColumnSetUser: [],
            dataCompareInfoColumnSetPackage: [],
            headerTable: [],
            userIds: [],
            packageIds: [],
            compareInfo: {},
            dataResponse: [],
            dataCompare: [],
            arrTitle: [
                {label: 'Kết quả đối soát giao dịch thanh toán', value: 5},
                {label: 'Kết quả đối soát gói dịch vụ camera', value: 2},
                {label: 'Kết quả đối soát tài khoản chi nhánh', value: 1},
            ],
            title: null
        }
    },
    methods:{
        findById(){
            let router = this.$route.query;
            this.filterHeader(router.templateType);
            const payload = {
                id: parseInt(router.id),
                templateType: parseInt(router.templateType)
            }
            return new Promise((resolve, reject) => {
                this.$crm.post('/history/find-by-id', payload).then((res) => {
                let result = res.data;
                result.packageIds = JSON.parse(result.packageIds);
                result.userIds = JSON.parse(result.userIds);
                result.paymentIds = JSON.parse(result.paymentIds);
                this.compareInfo = result;
                resolve(true);
            }).catch((err) => {reject()});
            })
        },
        formatMoneyIsVND(amount){
            amount = amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            return amount.concat(" VND");
        },
        getDataCompare: function() {
            let isUserIds = this.compareInfo.userIds && this.compareInfo.userIds.length > 0 ? true : false;
            let isPackageIds = this.compareInfo.packageIds && this.compareInfo.packageIds.length > 0 ? true : false;
            let isPaymentIds = this.compareInfo.paymentIds && this.compareInfo.paymentIds.length > 0 ? true : false;
            const payload = {
                id: this.compareInfo.id,
                templateType: this.compareInfo.templateType,
                userIds: isUserIds ? this.compareInfo.userIds : null,
                packageIds: isPackageIds ? this.compareInfo.packageIds : null,
                paymentIds: isPaymentIds ? this.compareInfo.paymentIds : null

            }
            this.$vs.loading();
            this.$crm.post('/history/find-by-template-type', payload).then((res) => {
                this.countDataDetail = res.data.countDataError;
                this.$vs.loading.close();
                this.filterData(res.data);
                let result = res.data;
                let totalCamera = 0;
                let totalAmount = 0;
                result.comparePackages.forEach(el => {
                    totalCamera += el.totalCamera;
                    totalAmount += el.amount;
                })
                if (this.compareInfo.templateType == 5) {
                    result.comparePayments.forEach(el => {
                        totalAmount += parseInt(el.amount);
                    })
                }
                this.dataCompareInfoColumnSetPackage.push();
                let fromDate = result.fromDate ? moment(result.fromDate).format("DD/MM/YYYY HH:ss") : null;
                let toDate = result.toDate ? moment(result.toDate).format("DD/MM/YYYY HH:ss") : null;
                this.dataCompareInfoColumnSetPackage = [
                    {title: 'Ngày tạo', value: moment(result.createdDate).format("DD/MM/YYYY")},
                    {title: 'Người tạo', value: result.createdBy},
                    {title: 'Tên file', value: result.fileName},
                    {title: 'Đối soát từ ngày', value: fromDate},
                    {title: 'Đến ngày', value: toDate},
                    {title: 'Tổng tiền giao dịch trong file', value: totalAmount + " VNĐ"},
                ]
                // if (this.compareInfo.templateType == 2) this.dataCompareInfoColumnSetPackage.push({title: 'Tổng số camera', value: totalCamera});
                this.publicURL = result.publicUrl.concat(result.fid);
            }).catch(() => {this.$vs.loading.close();})
        },
        filterData(data){
            let index = 0;
            switch (data.templateType) {
                case 1:
                    this.dataResponse = data.users.map(el => {
                        el.index = index++;
                        el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
                        el.paymentDate = moment(el.paymentDate).format("DD/MM/YYYY");
                        return el;
                    });
                    data.userIds = JSON.parse(data.userIds);
                    this.dataCompare = data.compareUsers.map(el => {
                        el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
                        el.paymentDate = moment(el.paymentDate).format("DD/MM/YYYY");
                        el.userIds = data.userIds;
                        return el;
                    });
                    break;
                case 2:
                    data.packageIds = JSON.parse(data.packageIds);
                    this.dataCompare = data.comparePackages.map(el => {
                        el.packageIds = data.packageIds;
                        el.paymentDate = moment(el.paymentDate).format("DD/MM/YYYY");
                        el.amount = this.formatMoneyIsVND(el.amount);
                        return el;
                    });   
                    let legnthData = this.dataCompare.length > data.comparePackageList.length ? data.comparePackageList.length : this.dataCompare.length;
                    this.dataResponse = data.comparePackageList.slice(0, legnthData);
                    this.dataCompare = this.dataCompare.slice(0, legnthData);
                    this.dataResponse = this.dataResponse.map(el => {
                        el.index = index++;
                        el.paymentDate = moment(el.paymentDate).format("DD/MM/YYYY")
                        el.amount = this.formatMoneyIsVND(el.amount);
                        return el;
                    })
                    break;
                case 5:
                    data.paymentIds = JSON.parse(data.paymentIds);
                    this.dataResponse = data.comparePaymentDTOS.map(el => {
                        el.index = index++;
                        el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
                        el.paymentDate = moment(el.paymentDate).format("DD/MM/YYYY");
                        el.paymentType = 'MoMo'
                        return el;
                    });
                    let result = data.comparePayments.map(el => {
                        el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
                        el.paymentDate = moment(el.paymentDate).format("DD/MM/YYYY");
                        el.paymentIds = data.paymentIds;
                        el.payType = el.payType && el.payType.includes("Admin") ? el.payType.slice(0, 5) : 'MoMo' 
                        return el;
                    });
                    this.dataCompare = result.sort((a, b) => parseInt(a.orderId) - parseInt(b.orderId));
                    break;
                default:
                    break;
            }
        },
        filterHeader: function(templateType){
            return new Promise((resolve, reject) => {
                switch (templateType) {
                    case 1:
                        this.headerTable = [
                            {title: 'Mã'},
                            {title: 'Tên tài khoản'},
                            {title: 'Ngày tạo'},
                            {title: 'Chú thích'},
                            {title: 'Trạng thái'},
                        ],
                        this.title = this.arrTitle[2].label;
                        resolve(true);
                        break;
                    case 2:
                        this.headerTable = [
                            {title: 'Mã'},
                            {title: 'Tên gói'},
                            {title: 'Tên camera'},
                            {title: 'Ngày giao dịch'},
                            {title: 'Số tiền'},
                            {title: 'Mô tả'},
                            {title: 'Trạng thái'}
                        ],
                        this.title = this.arrTitle[1].label;
                        resolve(true);
                        break;
                    case 5:
                        this.headerTable = [
                            {title: 'Mã hoá đơn'},
                            {title: 'Mã giao dịch'},
                            {title: 'Loại giao dịch'},
                            {title: 'Số tiền'},
                            {title: 'Ngày giao dịch'},
                            {title: 'Mô tả'},
                            {title: 'Trạng thái'}
                        ],
                        this.title = this.arrTitle[0].label;
                        resolve(true);
                        break;
                }
            })
        }
    },
    async created(){
        let router = this.$route.query;
        await this.filterHeader(parseInt(router.templateType));
        await this.findById();
        this.getDataCompare();
    }
}
</script>

<style lang="scss">
.padding-tr {
    padding: 0px 0px 0px 0px !important;
}
.compare th .vs-table-text {
    display: block !important;
}
</style>